const binding = { modules: {}, dataActions: {} };

    (binding.modules['spring-theme'] = {
        c: () => require('partner/themes/spring-theme/spring-theme.tsx'),
        $type: 'themeModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'spring-theme',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'spring-theme.theme.settings.json',
        md: 'src/themes/spring-theme'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };